<template>
  <div class="indexScreen" id="screenTotal">
    <div id="myMap">
<!--      <MapContainer :mapData="mapData" :key="componentKey" :deviceInfoList="baseList"
                    v-if="mapData.centerPosition && mapData.mapName" @onMarkerChange="handlerSelect"></MapContainer>-->


    </div>

    <div id="header">
      <p id="header_title" :style="{'font-size':bigTitleSize}">{{ nameScreen }}</p>

      <div class="link-btn">
        <span class="link-btn-item" v-for="(item,index) in linkList" :key="index"
              @click="handleLink(item.url)">{{ item.text }}
        </span>
      </div>
    </div>

    <div id="main">
      <div id="mainBox">
        <div id="lefts" :class="getFlag">
          <div class="leftBoxs">
            <div class="left2">
              <div class="boxTitile">
                <span>专家信息</span>
              </div>
              <div class="distance-first5">
                <vue-seamless-scroll
                    :data="productList"
                    :class-option="optionHover"
                    class="seamless-warp"
                >
                  <div class="distance-swiper">
                    <div
                        class="distance-content"
                        v-for="(item, index) in productList"
                        :key="index"
                        @ScrollEnd="onScrollEnd"
                    >

                      <div class="distance-content-right">
                        <div class="distance-content-right-text">
                          <img :src="item.hendImg" alt=""/>

                          <div class="distance-content-right-text-info">
                            <div>
                              <span style="color:#fff300;font-weight: bold;">{{ item.realName }}</span>
                            </div>
                            <div>
                              <span>{{ item.cards }}</span>
                            </div>
                            <div>
                              <span>{{ item.roleName }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </vue-seamless-scroll>
                <div v-if="productList.length == 0" class="nodata">
                  <span>暂无数据</span>
                </div>
              </div>
            </div>
            <div class="left3">
              <div class="boxTitile">
                <span>获得专利</span>
              </div>
<!--              <div id="echarts3"></div>-->
              <div class="carousel">
                <el-carousel :interval="3000" type="card" indicator-position="none">
                  <el-carousel-item v-for="item, index in pictureList1" :key="index">
                    <!-- <div class="imgBox"> -->
                    <img :src="getImgUrl(item.imgPath)" alt="" @click="carouselChange2(index)">
                    <!-- </div> -->
                  </el-carousel-item>
                </el-carousel>
              </div>

            </div>

          </div>
        </div>
<!--        <div id="center">{{ baseObj.baseName }}</div>-->

        <div id="rights" :class="!threeFlag3 ? 'translations2' : ''">
          <div class="rightBox">
            <div class="villageInfo">
              <div class="boxTitile">
                <span>科研成果展示</span>
              </div>
              <div class="carousel">
                <el-carousel :interval="3000" indicator-position="none">
                  <el-carousel-item v-for="item, index in pictureList3" :key="index">
                    <!-- <div class="imgBox"> -->
                    <img :src="getImgUrl(item.imgPath)" alt="" @click="carouselChange3(index)">
                    <!-- </div> -->
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <div class="survey">
              <div class="boxTitile">
                <span>科研投入</span>
              </div>
              <div id="echarts2"></div>
            </div>
            <div class="left2">
              <div class="boxTitile">
                <span>重点科研数据</span>
              </div>
              <dv-scroll-board  :config="warnConfig" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bottoms"></div> <img v-else :src="imgList[indexs]" alt="">   type="application/x-mpegURL" -->

    <!-- 图例 -->

<!--    <div class="legends">
      <div class="legendTop">图例</div>
      <div class="legendInfo">
        <div class="legendItem" >
          <img src="@/assets/cloud2/index/leg1.png" alt="">
          <span :class="iframeFlag1==0 ? 'actives' : ''">气象设备</span>
        </div>
        <div class="legendItem">
          <img src="@/assets/cloud2/index/leg3.png" alt="">
          <span :class="iframeFlag4==0 ? 'actives' : ''">水质设备</span>
        </div>

        <div class="legendItem" >
          <img src="@/assets/cloud2/index/leg2.png" alt="">
          <span :class="iframeFlag3==0 ? 'actives' : ''">土壤设备</span>
        </div>

        <div class="legendItem">
          <img src="@/assets/cloud2/index/leg6.png" alt="">
          <span :class="iframeFlag2==0 ? 'actives' : ''">摄像头</span>
        </div>
        <div class="legendItem" >
          <img src="@/assets/cloud2/index/leg4.png" alt="">
          <span :class="iframeFlag6==0 ? 'actives' : ''">虫情设备</span>
        </div>

        <div class="legendItem" >
          <img src="@/assets/cloud2/index/leg7.png" alt="">
          <span :class="iframeFlag7==0 ? 'actives' : ''">孢子设备</span>
        </div>
        <div class="legendItem">
          <img src="@/assets/cloud2/index/leg8.png" alt="">
          <span :class="iframeFlag8==0 ? 'actives' : ''">灌溉控制</span>
        </div>
      </div>
    </div>-->


    <!-- 图片弹框 -->
    <div class="imgBigBox" v-if="imgFlag">
      <div class="popUpTop">
        <span>图片</span>

        <span class="closeCss" @click="closeBox">x</span>
      </div>
      <div class="imgInfo">
        <div class="carouselBig">
          <!--          <video
                      v-if="indexs == 0"
                      id="my-video1"
                      class="video-js vjs-default-skin box"
                      controls
                      preload="auto"
                      muted
                      style="height: 100%; width: 100%; object-fit: fill"
                    >
                      <source src="" type="application/x-mpegURL" />
                    </video>-->
          <img :src="pictureList[indexs]" alt=""/>
        </div>
      </div>
    </div>
    <!-- 图片弹框 -->
    <div class="imgBigBox" v-if="imgFlag2">
      <div class="popUpTop">
        <span>图片</span>

        <span class="closeCss" @click="closeBox2">x</span>
      </div>
      <div class="imgInfo">
        <div class="carouselBig">
          <!--          <video
                      v-if="indexs == 0"
                      id="my-video1"
                      class="video-js vjs-default-skin box"
                      controls
                      preload="auto"
                      muted
                      style="height: 100%; width: 100%; object-fit: fill"
                    >
                      <source src="" type="application/x-mpegURL" />
                    </video>-->
          <img :src="getImgUrl(pictureList1[indexs2].imgPath)" alt=""/>
        </div>
      </div>
    </div>


    <!-- 图片弹框 -->

    <div class="imgBigBox" v-if="imgFlag3">
      <div class="popUpTop">
        <span>图片</span>

        <span class="closeCss" @click="closeBox3">x</span>
      </div>
      <div class="imgInfo">
        <div class="carouselBig">
          <!--          <video
                      v-if="indexs == 0"
                      id="my-video1"
                      class="video-js vjs-default-skin box"
                      controls
                      preload="auto"
                      muted
                      style="height: 100%; width: 100%; object-fit: fill"
                    >
                      <source src="" type="application/x-mpegURL" />
                    </video>-->
          <img :src="getImgUrl(pictureList3[indexs3].imgPath)" alt=""/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import "video.js/dist/video-js.css";
import videojs from "video.js";
import "videojs-contrib-hls";
import MapContainer from "./MapContainer";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  components: {MapContainer,vueSeamlessScroll},
  computed:{
    optionHover() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  data() {
    return {
      dates: "",
      week: "",
      weatherKey: "5a0bc17ca9a643dc84fee66d458770ce",
      weathers: [],
      text: "",
      city: "",
      county: "",
      temp: "12-18",
      windDir: "",
      windScale: "",
      imgList: [],
      bigTitleSize: "28px",
      pictureList: [],
      pictureList1: [],
      pictureList3: [],
      totalArea: "",
      partyMemberNumber: "",
      officialPartyMemberNumber: "",
      probationaryPartyMemberNumber: "",
      totalNumber: "",
      onlineNumber: "",
      offlineNumber: "",
      onlineList: [],
      offlineList: [],
      videoList: [],
      permanentPeoples: "",
      migrantPopulation: "",
      threeFlag1: 0,
      threeFlag2: 1,
      threeFlag3: true,
      imgFlag: false,
      imgFlag2: false,
      imgFlag3: false,
      indexs: 0,
      indexs2: 0,
      indexs3: 0,
      myPlayer: null,
      base: "",
      value1: "",
      user: "进入后台",
      iframeFlag1: 1,
      iframeFlag2: 1,
      iframeFlag3: 1,
      iframeFlag4: 1,
      iframeFlag5: 1,
      iframeFlag6: 1,
      iframeFlag7: 1,
      iframeFlag8: 1,
      switchList: [
        require("@/assets/index/open.png"),
        require("@/assets/index/close.png"),
      ],
      markersList: [],
      flood: [
        {title: "总流量", value: 224, status: 0},
        {title: "通道一流量", value: 224, status: 0},
        {title: "通道二流量", value: 224, status: 0},
        {title: "通道三流量", value: 224, status: 1},
      ],
      token: "",
      deviceNumber: "",
      baseList: [],//基地列表
      baseObj: {},
      allDevice: {},//所有设备wuq定义：{devicenum:info}
      allWeather: [],//wq定义所有气象设备
      allSolid: [],//wq定义所有土壤设备
      allWater: [],//wq定义所有水质设备
      allCtrl: [],//wq定义所有控制设备
      soilList: [],//基地对应土壤设备列表
      weatherList: [],//基地对应气象设备列表
      meteorList: [],//所有气象设备
      waterList: [],//基地对应水质设备列表
      insectList: [],//基地对应虫情设备列表
      cameraList: [],//基地对应摄像头设备列表
      ctrlList: [],//基地对应控制设备列表
      playVideoList: [],//所有摄像头设备，暂无用
      irrigationList: [],
      soildObj: [],//土壤--当前显示数据
      meteorObj: [],//气象--当前显示数据
      weatherObj: [],//气象--当前显示数据
      waterObj: [],//水质--当前显示数据
      ctrlSensorList: {},//控制--当前显示数据
      insectObj: {
        deviceName: "",
        deviceLocation: "",
      },
      warnList: [],
      SecretObj: {},
      videoUrl: "",
      video: "",
      soildName: "",
      weatherName: "",
      waterName: "",
      cameraName: "",
      ctrlName: "",//当前控制设备名称
      ctrlNum: "",//当前控制设备编号
      nowDate: "",
      dataTimer: null,
      imgObj: {
        '温度':require("@/assets/cloud2/index/item1.png"),
        '湿度':require("@/assets/cloud2/index/item2.png"),
        '光照':require("@/assets/cloud2/index/item3.png"),
        'PM2.5':require("@/assets/cloud2/index/item5.png"),
        'PM10':require("@/assets/cloud2/index/item6.png"),
        '大气压':require("@/assets/cloud2/index/item7.png"),
        '风向':require("@/assets/cloud2/index/item9.png"),
        '雨量':require("@/assets/cloud2/index/item10.png"),
        '二氧化碳':require("@/assets/cloud2/index/item4.png"),
        '风速':require("@/assets/cloud2/index/item1.png"),
        "其他":require("@/assets/cloud2/index/item1.png")
      },
      nameScreen: "",
      deviceTotal: 0,
      productConfig: {
        header: ['任务名称 ', '执行人', '时间'],
        data: [],
        align: ['center', 'center', 'center',]
      },
      linkList: [{
        text: '智慧园区',
        url: '/cloud2'
      }, {
        text: '智慧工厂',
        url: '/factory'
      }, {
        text: '研究院',
        url: '/yanjiuyuan'
      }, {
        text: '产业大数据',
        url: '/chanye'
      }, {
        text: '保鲜系统',
        url: '/baoxian'
      }, {
        text: '返回首页',
        url: '/xunyangNav'
      }],
      ctrolShow: 0,
      insectShow: 0,
      mapData: {
        centerPosition: '',
        mapName: '', // 地图名称
        polygonWkt: '',  // 图形json
        zoom: ''
      },
      componentKey: 1,
      productList:[],
      priceList:[],
      warnConfig:{
        header: ['位置 ', '项目名称', '检验数量'],
        data:[]
      },

    };
  },
  beforeMount() {
    window.localStorage.setItem("screenType", 0);
  },
  beforeDestroy() {
    if (this.$refs.myVideo) {
      this.$refs.myVideo.load();
    }
  },
  async mounted() {
    await this.getBaseList();
    await this.getVideo();
    await this.getPowerName();
    //await this.getWarnLog();
    await this.getWarnLog2();
    //await this.getSecret();//对接大友控制
    //await this.getCtrlJdrk();//对接建大仁科控制
    this.currentTime();
    this.getProductSuyuan()
    this.getPriceList()
    //this.getSellList()
    this.getKeyan()
    this.getGoods()
    this.getChengguo()
  },
  methods: {
    //科研成果
    getChengguo() {
      this.$get("/pro2501/goods/page", {page: 1, size: 1000, goodsType: '成果'}).then((res) => {
        console.log("成果：", res.data.data);

        this.pictureList3 = res.data.data;

      });


    },
    //专利
    getGoods() {
      this.$get("/pro2501/goods/page", {page: 1, size: 1000, goodsType: '专利'}).then((res) => {
        console.log("专利：", res.data.data);

        this.pictureList1 = res.data.data;

      });


    },
    // 重点科研数据
    getPriceList() {
      this.$get(`/pro2501/prodplan/page`, {page: 1, size: 20, type: "科研"}).then((res) => {
        if (res.data.state == "success") {
          this.warnConfig = {
            header: ['地理位置', '项目名称', '检验数量'],
            data: res.data.data.map(v => [v.origin, v.productName, v.quality]),
            align: ['center', 'center', 'center']
          }
        }
      });
    },
    // 科研投入
    getKeyan(dev){
      /*this.$get("/dptj/cgqqx4day", {
        deviceNum: dev
      }).then((res) => {
        if(res.data.state == 'success'){
          let datas = res.data.data;
          if(datas.length != 0){
            const seriesData =  datas.map(v=>{
              return {
                type: 'line',
                data: v.values,
                name:v.sensorName,
                smooth: true,
                yAxisIndex: 0,
                symbol: 'circle',
                symbolSize: 6,
                showSymbol:false,
                hoverAnimation:true,
              }
            })
            this.getEcharts(datas[0].times, seriesData)
          }
        }
      })*/
      this.getEcharts2()
    },
    //专利
    getSellList(val) {
      /*this.$get("/pro10/nsrw/listTask", {
        cropId: val,
      }).then((res) => {
        console.log(res, "产品溯源");
        res.data.data.forEach((element) => {
          element.taskImg = element.taskImg ? JSON.parse(element.taskImg) : [];
        });

        //this.productList = res.data.data;

      });*/
      //暂时写死假数据
      this.priceList =[
        {
          "name": "科研专利",
          "value":256
        },
        {
          "name": "科研专利2",
          "value":278
        }
      ];
      this.getEcharts3(this.priceList)


    },
    //专家信息列表
    getProductSuyuan(cpid) {
      this.$get("/userManage/getUserList", {page: 1, size: 1000, label: 1}).then((res) => {
        console.log("管理员：", res);
        /*res.data.datas.forEach((element) => {
          element.taskImg = element.taskImg ? JSON.parse(element.taskImg) : [];
        });*/
        this.productList = res.data.datas;

      });



    },
    onScrollEnd() {
      this.getProductSuyuan();
    },
    getImgUrl(name) {
      let imgUrl = "http://public.half-half.cn/" + name;
      return imgUrl;
    },
    resizeBigTitle() {
      let titLength = this.nameScreen.length;
      if (titLength <= 8) {
        this.bigTitleSize = "40px";
      } else if (titLength <= 10) {
        this.bigTitleSize = "38px";

      } else if (titLength <= 12) {
        this.bigTitleSize = "36px";

      } else {
        this.bigTitleSize = "26px";

      }
    },
    //建大仁科控制设备
    async getCtrlJdrk() {
      const {data} = await this.$get("/deviceManage/getDeviceInfos", {
        page: 1,
        size: 100,
        deviceNum: this.searchNum ? this.searchNum : '',
        deviceName: this.searchName ? this.searchName : '',
        // deviceCode: "",
        deviceType: 2,
      });
      this.ctrlList = data.datas;


      //console.log(this.irrigationList,"建大控制设备===================================")
    },
    //告警
    getWarnLog2() {
      this.$get(`/warnManage/pageWarnLogs`, {page: 1}).then((res) => {
        if (res.data.state == 'success') {
          console.log(res.data,'告警详情')
          this.warnList = res.data.datas
          let datas = res.data;
          let data = [
            {value: datas.size,name: '设备'},
            {value: datas.size, name: '火情'},
            {value: datas.size, name: '虫情'},
          ]
          let rate = (datas.datas.length / datas.size * 100).toFixed(2);
          this.getEcharts6(data,rate);

        }
      })
    },
    getPowerName() {
      this.$get("/largeScreenManagement/getLargeScreenManagements", {
        page: 1,
        size: 100,
      }).then((res) => {
        if (res.data.state == "success") {
          this.nameScreen = res.data.datas[0].name;
          this.mapData.zoom = res.data.datas[0].zoom;
          //this.linkList[0].show=res.data.datas[0].btn1==0?0:1;
          this.linkList[1].show = res.data.datas[0].btn2 == 0 ? 0 : 1;
          this.linkList[2].show = res.data.datas[0].btn3 == 0 ? 0 : 1;
          this.linkList[3].show = res.data.datas[0].btn4 == 0 ? 0 : 1;
          this.linkList[4].show = res.data.datas[0].btn5 == 0 ? 0 : 1;
          this.linkList[5].show = res.data.datas[0].btn6 == 0 ? 0 : 1;
          this.insectShow = res.data.datas[0].btn7 == 0 ? 0 : 1;
          this.ctrolShow = res.data.datas[0].btn8 == 0 ? 0 : 1;
          this.city = res.data.datas[0].city;
          this.county = res.data.datas[0].county;
          window.localStorage.setItem('screenRouter', res.data.datas[0].path);
          if (res.data.datas[0].btn7 == 0) {
            this.getInsectManage();
          }


          if (this.baseList.length == 0) {
            this.mapData.centerPosition = res.data.datas[0].location
          }
          //this.mapData.mapName = res.data.datas[0].userName
          this.resizeBigTitle();
          this.requestLookup();
        }
      });
    },
    toBack() {
      this.$router.push("/backHome");
    },
    toMonit() {
      this.$router.push("/monitScreen");
    },
    toOne() {
      this.$router.push("/oneScreen");
    },
    currentTime() {
      this.dataTimer = setInterval(() => {
        this.getDate();
      }, 1000);
    },
    //获取所有摄像头
    getVideo() {
      this.$get("/cameraManage/page", {page: 1, size: 1000, limit: 1000}).then((res) => {
        if (res.data.state == "success" && res.data.datas.length > 0) {
          //console.log("所有摄像头设备：",res.data)
          res.data.datas.forEach((item) => {
            let d = 'cameraId_' + item.id;
            this.allDevice[d] = item;
          });
        } else {
          //this.$message.info("账号暂未绑定摄像头")
        }
      });
    },
    // 查询基地列表
    getBaseList() {
      const data = {
        page: 1,
        size: 100,
      };
      this.$post("/baseManagement/getBaseManagements", data).then((res) => {
        if (res.data.state == "success") {
          this.baseList = res.data.datas;
          if (res.data.datas && res.data.datas.length > 0) {
            this.base = res.data.datas[0].id;
            this.mapData.centerPosition = res.data.datas[0].centerPos
            this.mapData.polygonWkt = res.data.datas[0].polygonWkt
            const imgList = res.data.datas[0].baseImg ? JSON.parse(res.data.datas[0].baseImg) : [];
            imgList.forEach(v => {
              console.log("图片：", v)
              this.pictureList.push("https://public.half-half.cn/" + v);//初始化第一个基地的图片
            })
            this.baseObj = res.data.datas[0];//初始化显示第一个基地信息
            this.mapData.mapName = res.data.datas[0].baseName//地图名称显示
          }
          this.getOverview(this.baseObj);//设备在线率
          this.getVillage();//获取所有设备及数据

        }
      });
    },
    // 获取账号所有设备信息
    getVillage() {
      this.$get("/deviceManage/getDeviceInfos", {page: 1, size: 10000, limit: 10000}).then(
          (res) => {
            if (res.data.state == "success") {
              res.data.datas.forEach((item) => {
                const d = item.deviceNum;
                this.allDevice[d] = item;//所有传感器设备信息，用于.deviceNum形式查询设备信息

              });
              //console.log("所有传感器设备：",this.allDevice)
              this.changeWeatherModule(0);//初始化显示第一个基地的气象下拉
              this.changeSolidModule(0)//初始化显示第一个基地的土壤下拉
              this.changeWaterModule(0)//初始化显示第一个基地的水质下拉
              this.changeCameraModule(0)//初始化显示第一个基地的摄像头下拉
              this.changeCtrlModule(0)//初始化显示第一个控制设备
            }
          }
      );
    },
    chooseSoil(val) {
      this.soildObj = this.getDeviceData(val);
      this.soildName = this.allDevice[val].deviceName;
      //console.log("选择土壤设备：",this.soildObj)
    },
    chooseWater(val) {
      this.waterObj = this.getDeviceData(val);
      this.waterName = this.allDevice[val].deviceName;
      //console.log("选择水质设备：",this.waterObj)
    },
    chooseWeather(val) {
      this.weatherObj = this.getDeviceData(val);
      this.weatherName = this.allDevice[val].deviceName;
      //console.log("选择气象设备：",this.weatherObj)

      //曲线切换
      //this.getData4day(val);
    },
    chooseVideo(val) {
      this.getVideoUrl(val);
      let d = 'cameraId_' + val;
      this.cameraName = this.allDevice[d].deviceName;
      //console.log("选择摄像头设备：",val)
    },
    chooseInsect(val) {
      this.getDeviceData(val);
    },
    chooseCtrl(val) {
      this.ctrlSensorList = this.getDeviceData(val);
      this.ctrlName = this.allDevice[val].deviceName;
      this.ctrlNum = val;
      //console.log("选择控制设备：",val)
    },
    changStatus(msg, deviceNum, index) {
      let sFlagTxt = msg == "1" ? "开启" : "关闭";

      this.$confirm(`此操作将${sFlagTxt}该开关, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            if (msg == "1") {
              this.$post(`/jdrk/open`, {
                deviceNum: deviceNum,
                relayId: index + 1,
              }).then((res) => {
                if (res.data.state == "success") {
                  this.$message.success(`执行成功`);
                } else {
                  this.$message.error(`执行失败`)
                }
              });
            } else if (msg == "0") {
              this.$post(`/jdrk/close`, {
                deviceNum: deviceNum,
                relayId: index + 1,
              }).then((res) => {
                if (res.data.state == "success") {
                  this.$message.success(`执行成功`);
                } else {
                  this.$message.error(`执行失败`);

                }
              });
            }
          })


    },
    handlerSelect(val) {//切换基地
      //console.log("切换基地：",this.baseList)
      this.baseList.forEach((item, index) => {
        if (val == item.id) {
          this.baseObj = item;//当前基地所有信息
          this.mapData.centerPosition = item.centerPos;//切换基地中心点
          this.mapData.polygonWkt = item.polygonWkt//展示基地矢量图形
          this.mapData.mapName = item.baseName//地图基地名称
          this.componentKey += 1;//地图
          this.changeBaseList(item)//切换基地图片
          this.changeSolidModule(index);//切换土壤模块
          this.changeWaterModule(index);//切换水质模块
          this.changeWeatherModule(index);//切换气象模块
          this.changeCameraModule(index);//切换摄像头模块
          this.getOverview(this.baseObj);//切换设备总览
        }
      });
    },
    //基地切换土壤设备
    changeSolidModule(baseIndex) {
      this.soilList = [];
      let base = this.baseList[baseIndex]
      let deviceNums = JSON.parse(base.soilDevice);
      if (deviceNums.length > 0) {
        for (let i = 0; i < deviceNums.length; i++) {
          let item = deviceNums[i];
          this.soilList.push(this.allDevice[item])
        }
        //console.log("当前土壤设备列表：",this.soilList)
        this.chooseSoil(this.soilList[0].deviceNum)
      }


    },

    //基地切换气象设备
    changeWeatherModule(baseIndex) {
      this.weatherList = [];
      let base = this.baseList[baseIndex]

      let deviceNums = JSON.parse(base.weatherDevice);
      if (deviceNums.length > 0) {
        for (let i = 0; i < deviceNums.length; i++) {
          let item = deviceNums[i];
          //console.log(this.allDevice[item])
          this.weatherList.push(this.allDevice[item])
        }
        //console.log("当前气象设备列表：",this.weatherList)
        this.chooseWeather(this.weatherList[0].deviceNum)

      }

    },
    //基地切换水质设备
    changeWaterModule(baseIndex) {
      this.waterList = [];
      let base = this.baseList[baseIndex]
      let deviceNums = JSON.parse(base.waterDevice);
      if (deviceNums.length > 0) {
        for (let i = 0; i < deviceNums.length; i++) {
          let item = deviceNums[i];
          this.waterList.push(this.allDevice[item])
        }
        //console.log("当前水质设备列表：",this.waterList)
        this.chooseWater(this.waterList[0].deviceNum)
      }

    },
    //基地切换控制设备
    changeCtrlModule(baseIndex) {
      /*this.ctrlList=[];
      let ctrl=this.ctrlList[baseIndex].realData.split("|");
      for (let i = 0; i < ctrl.length; i++) {
        this.ctrlList.push({
          "name": ctrl[i].split("·")[0],
          "itemId": i,
        });
      }*/
      //console.log("控制设备列表：",this.ctrlList)
      this.chooseCtrl(this.ctrlList[0].deviceNum)


    },
    //基地切换摄像头设备
    changeCameraModule(baseIndex) {
      this.cameraList = [];
      let base = this.baseList[baseIndex]
      let deviceNums = JSON.parse(base.cameraDevice);
      if (deviceNums.length > 0) {
        for (let i = 0; i < deviceNums.length; i++) {
          let item = "cameraId_" + deviceNums[i];
          this.cameraList.push(this.allDevice[item])
        }
        //console.log("当前摄像头设备列表：",this.cameraList)
        this.chooseVideo(this.cameraList[0].id)//默认播放第一个
      } else {
        this.cameraName = "无设备"
        //this.$message.info("基地未绑定摄像头设备")
      }

    },
    //设备详情
    getDeviceInfo(val) {
      let obj = {};
      this.$get(`/deviceManage/getDeviceSensor/${val}`, {}).then((res) => {
        if (res.data.state == "success") {
          obj = res.data.data;
        }
      });
      return obj;
    },
    //生产数据
    getWarnLog() {
      this.$get(`/pro10/nsrw/pageTask`, {page: 1}).then((res) => {
        if (res.data.state == "success") {
          //console.log(res.data, "告警详情");
          // this.warnList = res.data.datas;
          const columnData = res.data.data.map(v => {
            return [v.taskName, v.zxrmc, v.createTime]
          })
          this.productConfig = {
            header: ['任务名称 ', '执行人', '时间'],
            data: columnData,
            columnWidth: [120, 120, 200],
            align: ['center', 'center', 'center',]
          }
          //console.log(this.productConfig)
        }
      });
    },
// 切换基地
    changeBaseList(item) {
      console.log("切换基地为：", item);
      if (item.baseImg.length) {
        this.pictureList = [];//清空基地图片
        const imgList = item.baseImg ? JSON.parse(item.baseImg) : [];
        imgList.forEach(v => {
          this.pictureList.push("https://public.half-half.cn/" + v);//初始化第一个基地的图片
        })
        //console.log("基地图片：",this.pictureList)

      }
      this.getOverview(this.baseObj)//更新设备统计模块


    },

    //获取展示设备数据
    getDeviceData(dev) {

      if (dev == null) {
        return [];
      } else {
        let dataList2 = [];
        let datas = this.allDevice[dev];
        let keys = [];
        let data = [];
        if (datas.hasOwnProperty("realData")) {
          let realData = datas.realData.replace(/null\|/g, '').replace(/\|null/g, '').split("|");
          for (let a = 0; a < realData.length; a++) {
            keys.push(realData[a].split("·")[0]);
            data.push(realData[a].split("·")[1]);
          }
          for (let i = 0; i < keys.length; i++) {
            dataList2.push({name: keys[i], data: data[i]});
          }
          console.log('设备号返回数据：',dataList2);
        }
        return dataList2;
      }
    },

    //获取虫情设备
    getInsectManage() {
      this.$get("/insectManage/page", {page: 1, size: 100}).then((res) => {
        if (res.data.state == "success") {
          if (res.data.datas.length != 0) {
            res.data.datas.forEach((item) => {
              this.insectList.push(item);
              item["deviceLocation"] = item.latitudeAndLongitude;
            });
          }
          if (this.insectList.length) {
            this.getInsect(res.data.datas[0].deviceNumber);
            this.$set(this.insectObj, "deviceName", this.insectList[0].name);
            this.$set(
                this.insectObj,
                "deviceLocation",
                this.insectList[0].latitudeAndLongitude
            );
          } else {
            this.$set(this.insectObj, "deviceName", "");
            this.$set(this.insectObj, "deviceLocation", "");
          }
        }
      });
    },

    getPointInfoFor(deviceNumber) {
      this.$get("/deviceCtrl/pageDataPointInfoForCusdeviceNo", {
        page: 1,
        size: 100,
        deviceNum: deviceNumber,
      }).then((res) => {
        if (res.data.state == "success") {
          this.irrigationList = res.data.data.cusdeviceDataPointList;
        }
      });
    },
    getSecret() {
      this.$get("/deviceCtrl/getSecret").then((res) => {
        if (res.data.state == "success") {
          //console.log(res.data, "getSecret");
          this.SecretObj = res.data.data;
          this.getPointInfoFor(res.data.data.deviceNumber);
        }
      });
    },
    //获取摄像头url
    getVideoUrl(deviceNumId) {
      this.$get(`/cameraManage/realPlay/${deviceNumId}?protocol=2`).then(
          (res) => {
            if (res.data.state == "success") {
              //console.log(res.data.datas, "====获取摄像头地址");
              this.videoUrl = res.data.data.url;
              // this.$refs.myVideo.src=res.data.data.url
              const that = this;
              setTimeout(() => {
                that.lookCamera(res.data.data.url);
              }, 2000);
            } else {
              this.$message.error("摄像头播放地址获取失败，请检查设备")
            }
          }
      );
    },


    getDetail(id) {
      const obj = {};
      this.$get(`/insectManage/detail/${id}`, {}).then((res) => {
        if (res.data.state == "success") {
          obj = res.data.data;
        }
      });
      return obj;
    },

    // 获取虫情设备信息
    getInsect(deviceNumber) {
      this.$get("/insectManage/pageHist", {
        page: 1,
        size: 100,
        deviceNumber: deviceNumber,
      }).then((res) => {
        if (res.data.state == "success") {
          this.insectObj = Object.assign({}, this.insectObj, res.data.datas[0]);
          //console.log(this.insectObj, "=====虫情", res.data.datas[0]);
        }
      });
    },

    closeBox() {
      this.imgFlag = false;
    },
    closeBox2() {
      this.imgFlag2 = false;
    },
    closeBox3() {
      this.imgFlag3 = false;
    },
    getFlag() {
      return !this.threeFlag3 ? "translations" : "";
    },
    carouselChange(i) {
      this.imgFlag = true;
      this.indexs = i;
      /*if (i == 0) {
         this.lookCamera();
      }*/
    },
    carouselChange2(i) {
      this.imgFlag2 = true;
      this.indexs2 = i;
      /*if (i == 0) {
         this.lookCamera();
      }*/
    },
    carouselChange3(i) {
      this.imgFlag3 = true;
      this.indexs3 = i;
      /*if (i == 0) {
         this.lookCamera();
      }*/
    },
    // 获取任意时间
    getDay(day) {
      var today = new Date();
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds);

      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "/" + tMonth + "/" + tDate;
    },
    doHandleMonth(month) {
      var m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },
    // 获取星期
    getCurrentWeek() {
      const days = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      const date = new Date();
      const index = date.getDay(); // 0-6,0 表示星期日
      this.week = days[index];
    },
    // 天气
    requestLookup() {
      this.$axios({
        method: "GET",
        url: "https://geoapi.qweather.com/v2/city/lookup",
        params: {adm: this.city, location: this.county, key: this.weatherKey},
      }).then((res) => {
        if (res.status == 200) {
          let {location} = res.data;
          this.requestweather7d(location);
        }
      });
    },
    // 实时天气
    requestweather7d(location) {
      this.$axios({
        method: "GET",
        url: "https://devapi.qweather.com/v7/weather/now",
        params: {location: location[0].id, key: this.weatherKey},
      }).then((res) => {
        if (res.status == 200) {
          this.weathers = res.data.now;
          this.text = res.data.now.text;
          this.temp = res.data.now.temp;
          this.windDir = res.data.now.windDir;
          this.windScale = res.data.now.windScale;
        }
      });
    },
    lookCamera(src) {
      this.$nextTick(() => {
        var myVideo = this.$refs.myVideo;
        this.myPlayer = videojs(myVideo, {
          controls: true, //控件显示
          bigPlayButton: true,
          autoplay: false,
          preload: "auto",
          hls: true,
        });
        this.myPlayer.src({
          src: src,
          type: "application/x-mpegURL",
        });
        //this.myPlayer.play();//立即播放
        // this.videoArr.push(this.myPlayer);
      });
    },
    // lookCamera(src) {
    //   this.$nextTick(() => {
    //     this.myPlayer = videojs("my-video", {
    //      // autoplay: true, //自动播放
    //       controls: true, //控件显示
    //       bigPlayButton: true,
    //       autoplay: 'muted',
    //       preload: 'auto',
    //       //hls: true,
    //     });
    //     /* this.myPlayer.src({
    //       src: src,
    //       type:'rtmp/flv',
    //      // type: "application/x-mpegURL"
    //     });
    //     this.myPlayer.play(); */
    //   })
    // },

    // 获取在线设备
    getOverview(item) {
      this.$get("/dptj/sbzl", {
        //key:{id:item.id}
      }).then((res) => {
        if (res.data.state == "success") {
          let datas = res.data
          this.totalNumber=datas.totalNumber
          this.onlineNumber=datas.onlineNumber
          this.offlineNumber=datas.offlineNumber
          datas.data.forEach(item=>{
            this.onlineList.push(item.onlinecount)
            this.offlineList.push(item.offlinecount)
          })
          this.initEcharts()
        }
      });
    },
    //获取当前时间
    getDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      month = month < 10 ? "0" + month : month; // 如果只有一位，则前面补零
      let day = date.getDate(); // 日
      day = day < 10 ? "0" + day : day; // 如果只有一位，则前面补零
      let week = date.getDay(); // 星期
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.nowDate = `${year}-${month}-${day} ${hour}:${minute}:${second}  `;
    },
    handleLink(url) {
      this.$router.push(url);
    },
    // 设备曲线数据
    getData4day(dev){
      this.$get("/dptj/cgqqx4day", {
        deviceNum: dev
      }).then((res) => {
        if(res.data.state == 'success'){
          let datas = res.data.data;
          if(datas.length != 0){
            const seriesData =  datas.map(v=>{
              return {
                type: 'line',
                data: v.values,
                name:v.sensorName,
                smooth: true,
                yAxisIndex: 0,
                symbol: 'circle',
                symbolSize: 6,
                showSymbol:false,
                hoverAnimation:true,
              }
            })
            this.getEcharts(datas[0].times, seriesData)
          }
        }
      })
    },

    getEcharts(times, seriesData){
      let chartDom = document.getElementById('echarts1');
      let myChart = echarts.init(chartDom);
      let obj = {
        xData: times,
        lineData: seriesData/*[
          {
            name: '温度',
            data: [20, 19, 20, 23, 25, 29, 35],
          },

        ]*/
      }
      let colorStop=[
        {
          start:'rgba(0, 142, 255, 0.1)',
          end:'rgba(0, 142, 255, 0.6)'
        },
        {
          start:'rgba(0, 242, 240,0.1)',
          end:'rgba(0, 242, 240,06)'
        },
        {
          start:'rgba(255, 219, 14,0.1)',
          end:'rgba(255, 219, 14, 0.6)'
        },
        {
          start:'rgba(102, 225, 223,0.02)',
          end:'rgba(102, 225, 223, 0.6)'
        },
        {
          start:'rgba(255, 173, 106,0.02)',
          end:'rgba(255, 173, 106, 0.6)'
        },
        {
          start:'rgba(255, 224, 171,0.02)',
          end:'rgba(255, 224, 171, 0.6)'
        },
        {
          start:'rgba(107, 197, 244,0.02)',
          end:'rgba(107, 197, 244, 0.6)'
        },
        {
          start:'rgba(192, 142, 242,0.02)',
          end:'rgba(192, 142, 242, 0.6)'
        },
      ]
      let serData = []
      obj.lineData.forEach((item,index) => {
        serData.push({
          name: item.name,
          type: "line",
          // stack: "all",
          showSymbol: true,
          smooth: true,
          symbolSize: 8,
          symbol: "emptyCircle",
          lineStyle: {
          },
          itemStyle: {
            borderWidth: 2,
          },
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color:colorStop[index]?colorStop[index].end:'rgba(0, 142, 255, 0.6)',
                },
                {
                  offset: 1,
                  color: colorStop[index]?colorStop[index].start:'rgba(0, 142, 255, 0.02)',
                },
              ],
              globalCoord: false, // 缺省为 false
            },
          },
          data: item.data,
        })
      })

      let option = {
        color: [
          '#159AFF',
          '#66E193',
          '#E3F170',
          '#66E1DF',
          '#FFAD6A',
          '#ffe0ab',
          '#6bc5f4',
          '#c08ef2',
        ],
        legend: {
          icon: 'circle',
          top: '0',
          left: 'center',
          orient: 'horizontal', //图例方向【horizontal/vertical】
          itemHeight: 10, //修改icon图形大小
          itemWidth: 10, //修改icon图形大小
          itemGap: 10,
          textStyle: {
            fontSize: 12,
            color: '#fff',
            padding: [0, 5],
          },
          // data: obj.legend,
        },
        tooltip: {
          show: true,
          trigger: "axis",
          borderColor: "rgba(255, 255, 255, .16)",
          axisPointer: {
            lineStyle: {
              color: "rgba(28, 124, 196)",
            },
          },

          // formatter: (params) => {
          //    let arr = [...params];
          //    let str = '';
          //    arr.forEach((item, index) => {
          //       str += item.marker + item.seriesName + '  ' + item.data + '<br />';
          //    });
          //    str = arr[0].name + '<br />' + str;
          //    return str;
          // },
        },
        grid: {
          top: '30%',
          left: '10%',
          right: '10%',
          bottom: '18%',
          // containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
              lineStyle: {
                color: '#379E98AB',
              },
            },
            axisLabel: {
              show: true,
              interval: 0, //横轴信息全部显示
              // rotate:-30,//-30度角倾斜显示
              textStyle: {
                color: '#fff', // 更改坐标轴文字颜色
                fontSize: 12, // 更改坐标轴文字大小
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            boundaryGap: false,
            data:obj.xData,
          },
        ],

        yAxis: [
          {
            type: "value",
            name: "温度：℃",
            min: 0,
            nameTextStyle: {
              color: "#fff",
              fontSize: 12,
              padding: [20, 20, 0, 25]
            },
            // splitNumber: 6,
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#999',
                type: "dotted",
              }
            },
            axisLabel: {
              formatter: '{value}',
              textStyle: {
                color: "#ccc", fontSize: 12,
              }
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: serData,
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", function() {
        option && myChart.resize();
      });
    },
    initEcharts() {
      let myChart = echarts.init(document.getElementById('echart'));
      let option = {
        //  title: { text: '报表展示' },
        grid: {
          left: 40,
          top:30,
          // containLable: true
        },
        tooltip: {},
        xAxis: {
          data: ["气象", /*"土壤",*/ "水质", "摄像头"/* ,"水肥机", "虫情", "袍子"*/],
          axisLine: {
            lineStyle: {
              type: 'solid',
              color: '#eeeeee', //x左边线的颜色
              fontSize: 13,
              width: '0.5' //坐标线的宽度
            }
          },
          axisLabel: { //x轴字体
            textStyle: {
              color: '#fff',
              fontSize: 13
            }
          },
        },
        yAxis: {
          type: 'value',
          name: "数量（台）",
          nameTextStyle: {
            color: "#fff",
            nameLocation: "start",
          },
          axisLine: {
            lineStyle: {
              type: 'solid',
              color: '#fff', //左边线的颜色
              width: '0.5' //坐标线的宽度
            }
          },
          axisLabel: {
            textStyle: {
              color: '#fff',
              fontSize: 13
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#fff'],
              width: 1,
              type: 'dashed'
            }
          }

        },
        legend: {
          itemWidth: 11,
          itemHeight: 12,
          //symbolKeepAspect: true,
          textStyle: {
            color: '#1F6AAB',
            fontSize: 11,
            lineHeight: 0,
            backgroundColor: "rgba(11, 164, 19, 1)"
          },


          data: [
            {
              name: '在线',
            },
            {
              name: '离线',
            }
          ],
          align: 'left',
          right: 40,
          top: '0',
          textStyle: {
            fontSize: 12,
            textAlign: 'center',
            color: '#fff',
            magrinRight: 3
          },
        },
        series: [{
          name: '在线',
          type: 'bar',
          data: this.onlineList ,//[ 2330.0, 4432.9, 7324.0, 2342.2, 2544.6, 7623.7,5643],
          barWidth: 16, //柱子宽度
          barGap: '50%',//间距
          label: {
            //show: true,
            position: 'top',
            textStyle: {
              color: '#4695F3'
            },
            /*  formatter: function (params) {
               return params.value
             } */
          },
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#4695F3'
              }, {
                offset: 1,
                color: '#0d1c3d'
              }]),
              barBorderRadius: [4, 4, 0, 0],
            }
          },


        },
          {
            name: '离线',
            type: 'bar',
            data: this.offlineList , //[2433.0, 2324.2, 3548.3, 4535.5, 3454.3,5456,6565],
            barWidth: 16,
            barGap: '50%',
            label: {
              // show: true,
              position: 'top',
              textStyle: {
                color: '#FE8401'
              },
              formatter: function (params) {
                return params.value
              }
            },
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#a5c6bb'
                }, {
                  offset: 1,
                  color: '#25406b'
                }]),
                barBorderRadius: [4, 4, 0, 0],
              },
              emphasis: {
                barBorderRadius: 30
              },
            }
          }
        ]
      }



      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      myChart.off('click');
      myChart.on('click', param => {
      });

      window.addEventListener("resize", function () {
        myChart.resize();
      })
    },

    getEcharts2(){
      var chartDom = document.getElementById('echarts2');
      var myChart = echarts.init(chartDom);
      let obj = {
        xData: ['2022','2023','2024'],
        lineData: [

          {
            name: '资金',
            data: [10, 16, 18],
          },

        ]
      }
      let colorStop=[
        {
          start:'rgba(0, 142, 255, 0.1)',
          end:'rgba(0, 142, 255, 0.6)'
        },
        {
          start:'rgba(0, 242, 240,0.1)',
          end:'rgba(0, 242, 240,06)'
        },
        {
          start:'rgba(255, 219, 14,0.1)',
          end:'rgba(255, 219, 14, 0.6)'
        },
        {
          start:'rgba(102, 225, 223,0.02)',
          end:'rgba(102, 225, 223, 0.6)'
        },
        {
          start:'rgba(255, 173, 106,0.02)',
          end:'rgba(255, 173, 106, 0.6)'
        },
        {
          start:'rgba(255, 224, 171,0.02)',
          end:'rgba(255, 224, 171, 0.6)'
        },
        {
          start:'rgba(107, 197, 244,0.02)',
          end:'rgba(107, 197, 244, 0.6)'
        },
        {
          start:'rgba(192, 142, 242,0.02)',
          end:'rgba(192, 142, 242, 0.6)'
        },
      ]
      let serData = []
      obj.lineData.forEach((item,index) => {
        serData.push({
          name: item.name,
          type: "line",
          // stack: "all",
          showSymbol: true,
          smooth: true,
          symbolSize: 8,
          symbol: "emptyCircle",
          lineStyle: {
          },
          itemStyle: {
            borderWidth: 2,
          },
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color:colorStop[index]?colorStop[index].end:'rgba(0, 142, 255, 0.6)',
                },
                {
                  offset: 1,
                  color: colorStop[index]?colorStop[index].start:'rgba(0, 142, 255, 0.02)',
                },
              ],
              globalCoord: false, // 缺省为 false
            },
          },
          data: item.data,
        })
      })

      let option = {
        color: [
          '#159AFF',
          '#66E193',
          '#E3F170',
          '#66E1DF',
          '#FFAD6A',
          '#ffe0ab',
          '#6bc5f4',
          '#c08ef2',
        ],
        legend: {
          icon: 'circle',
          top: '5%',
          left: 'center',
          orient: 'horizontal', //图例方向【horizontal/vertical】
          itemHeight: 10, //修改icon图形大小
          itemWidth: 10, //修改icon图形大小
          itemGap: 10,
          textStyle: {
            fontSize: 12,
            color: '#fff',
            padding: [0, 5],
          },
          // data: obj.legend,
        },
        tooltip: {
          show: true,
          trigger: "axis",
          borderColor: "rgba(255, 255, 255, .16)",
          axisPointer: {
            lineStyle: {
              color: "rgba(28, 124, 196)",
            },
          },

          // formatter: (params) => {
          //    let arr = [...params];
          //    let str = '';
          //    arr.forEach((item, index) => {
          //       str += item.marker + item.seriesName + '  ' + item.data + '<br />';
          //    });
          //    str = arr[0].name + '<br />' + str;
          //    return str;
          // },
        },
        grid: {
          top: '20%',
          left: '10%',
          right: '10%',
          bottom: '25%',
          // containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
              lineStyle: {
                color: '#379E98AB',
              },
            },
            axisLabel: {
              show: true,
              interval: 0, //横轴信息全部显示
              // rotate:-30,//-30度角倾斜显示
              textStyle: {
                color: '#fff', // 更改坐标轴文字颜色
                fontSize: 12, // 更改坐标轴文字大小
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            boundaryGap: false,
            data:obj.xData,
          },
        ],

        yAxis: [
          {
            type: "value",
            name: "资金：万元",
            min: 0,
            nameTextStyle: {
              color: "#fff",
              fontSize: 12,
              padding: [20, 20, 0, 25]
            },
            // splitNumber: 6,
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#999',
                type: "dotted",
              }
            },
            axisLabel: {
              formatter: '{value}',
              textStyle: {
                color: "#ccc", fontSize: 12,
              }
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: serData,
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", function() {
        option && myChart.resize();
      });
    },

    getEcharts3(data1){
      var chartDom = document.getElementById('echarts3');
      var myChart = echarts.init(chartDom);

      // 底部立体柱子
      let bottomBar = data1.map((item, index) => {
        return {
          value: item.value,
          itemStyle: {
            normal: {
              color:'rgba(86, 253, 184,.9)' /*{

                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                type: 'linear',
                global: false,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(86, 253, 184,.9)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(86, 253, 184,0)',
                  },
                ],
              }*/,
              barBorderRadius: [5, 5, 5, 5],
            },
          },
        };
      });


      let option = {
        tooltip: {
          trigger: 'axis',
          borderColor: "rgba(255, 255, 255, .16)",
          axisPointer: {
            lineStyle: {
              color: "rgba(28, 124, 196)",
            },
          },
        },
        legend: {
          align: 'center',
          top: '0',
          textStyle: {
            color: '#fff',
            fontSize: 12,
            padding: [0, 50, 0, 20],
          },
          itemGap: 30,
          itemWidth: 15,
          itemHeight: 10,
          data: [
            {
              itemStyle: {
                color: 'rgba(86, 253, 184,.9)',
              },
            },
          ],

        },
        grid: {
          top: '20%',
          left: '10%',
          right: '5%',
          bottom: '18%',
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,//两侧留白
          axisLine: {
            show: true,
            lineStyle: {
              color: '#379E98AB',
            },
          },
          axisLabel: {
            interval: 0,
            textStyle: {
              color: '#fff', // 更改坐标轴文字颜色
              fontSize: 12, // 更改坐标轴文字大小
            },
          },
          axisTick: {
            show: false,
            lineStyle: {
              color: 'rgba(2, 119, 175)', // 刻度线颜色
              width: 2, // 刻度线宽度
            },
            length: 6, // 刻度线的长度
          },
          data: data1.map(v => v.name)
        },
        yAxis: {
          name: '数量',
          type: 'value',
          min: "1",
          nameTextStyle: {
            color: "#fff",
            fontSize: 12,
            padding: [20, 20, 0, 25]
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#999',
              type: "dotted",
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#999",
            },
          },
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: "#fff", fontSize: 12,
            }
          },
        },
        series: [
          //底部立体柱
          {
            z: 30,
            // stack: '1',
            type: 'bar',
            silent: true,
            barWidth: 10,
            data: bottomBar,
            itemStyle: {
              emphasis: {
                // 普通图表的高亮颜色
                // color: 'rgba(38, 207, 254)',
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  type: 'linear',
                  global: false,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(86, 253, 184,.9)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(86, 253, 184,0)',
                    },
                  ],
                },
                borderWidth: 1,
                borderColor: 'rgba(86, 253, 184)',
              }
            },
          },

        ],
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", function() {
        option && myChart.resize();
      });
    },
    getEcharts6(data, rate){
      console.log(data,rate)
      let chartDom = document.getElementById('echarts6');
      let myChart = echarts.init(chartDom);
      let option = {
        // 设置占比图颜色
        color: ['#FBBF3E', '#6AD2FB', '#32AE70'],
        tooltip: {
          //提示框
          trigger: 'item',
          formatter: '{b} : <br/> {c} ({d}%)',
        },
        legend: {
          show: true,
          orient: 'horizontal ',
          top: "85%",
          left: "15%",
          textStyle: {
            color: '#fff',
            fontSize: 12,
          },
          icon: 'roundRect',
          // data: dataList,
        },
        title: [
          // 中心换行标题
          {
            text: '报警占比',
            left: 'center',
            top: '43%',
            textStyle: {
              fontSize: 11,
              fontWeight: '400',
              color: '#fff',
            },
          },
          {
            text: rate + "%",
            left: 'center',
            top: '25%',
            textStyle: {
              fontSize: 14,
              fontWeight: '600',
              color: '#fff',
              fill: 'red',
            },
          },
        ],
        series: [
          {
            type: 'pie',
            name: '占比',
            startAngle: 0,
            label: {
              // 点击展示方式
              show: false,
            },
            radius: ['60%', '80%'], // 饼图大小设置
            center: ['50%', '40%'], //饼图位置
            /*data: [
               { value: 288, name: '事业单位' },
                { value: 72, name: '社区' },
                { value: 72, name: '企业' },
           ],*/
            data: data
          },
          // 最外面的圆环
          {
            type: 'pie',
            color: ['#1E4E53'],
            radius: ['89%', '90%'],
            center: ['50%', '40%'],
            label: {
              show: false,
            },
            select: {
              display: false,
            },
            tooltip: {
              show: false,
            },
            data: [25],
          },
          {
            type: 'pie',
            name: '中心圆', //最里面的圆
            radius: ['0%', '50%'],
            center: ['50%', '40%'],
            color: '#1E4E53', //内圆颜色
            itemStyle: {
              normal: {
                // 设置阴影
                shadowBlur: 30,
                shadowColor: 'rgba(30, 78, 83, 0.8)',
                shadowOffsetX: 0,
                shadowOffsetY: 7,
              },
            },
            label: {
              normal: {
                show: false,
              },
              emphasis: {
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
              emphasis: {
                show: false,
              },
            },
            animation: true,
            tooltip: {
              show: false,
            },
            data: [
              {
                value: 1,
              },
            ],
          },
        ],
      }

      option && myChart.setOption(option);
      window.addEventListener("resize", function() {
        option && myChart.resize();
      });
    },
  },
};
</script>

<style src="../../../style/font/font.css" scoped></style>
<style scoped lang="less">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: #fff;
  text-align: justify;

}
#screenTotal {
  position: relative;
  width: 100%;
  height: 100%;

  // background-size: 100% 100%;
  // background-repeat: no-repeat;
}

#header {
  width: 100%;
  height: 90px;
  position: fixed;
  left:0;
  top:0;
  text-align: center;
  overflow: hidden;
  z-index: 45;
  background-image: url(~@/assets/cloud2/index/img.png);
  background-size: 900px;
  background-position: center top;
  background-repeat: no-repeat;

  p {
    font-size: 40px;
    background: linear-gradient(to bottom, #fff 50%, #b8f9fd 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Title";
    letter-spacing: 4px;
    /*font-style: italic;*/
    line-height: 85px;
    text-align: center;

  }

  .date {
    position: absolute;
    top: 30%;
    left: 1%;
    font-size: 14px;
    width: 170px;

    .sun {
      width: 24px;
      height: 24px;
      vertical-align: bottom;
    }

    span {
      margin-left: 10px;
    }
  }

  .weather {
    position: absolute;
    top: 34%;
    z-index: 99;
    right: 17.3%;
    font-size: 14px;
    width: 170px;
    height: 40px;

    span {
      margin-right: 10px;
      float: left;
    }
  }

  .user {
    position: absolute;
    top: 30%;
    z-index: 99;
    right: 3%;
    font-size: 14px;
    width: 170px;
    height: 40px;
    cursor: pointer;

    img {
      width: 30px;
      height: 30px;
    }

    span {
      position: relative;
      top: -8px;
      left: 6px;
    }
  }

  .backs {
    position: absolute;
    top: 34%;
    right: 3%;
    font-size: 16px;
    cursor: pointer;
    font-family: "mokuaiTitile";
    background: linear-gradient(to bottom, #fff 50%, #b8f9fd 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

#main {
  position: relative;
  // z-index: 10;
  width: 100%;
  height: 100%;
  left:0;
  top:0;
  //margin-top: -5%;
  background-image: url(~@/assets/cloud2/index/bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  pointer-events: none;
}

#myMap {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;

  background-image: url("~@/assets/temp/guaizao3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;




  //pointer-events: none;
  .iframeBox {
    width: 100%;
    height: 100%;
    border: none;
  }
}

#mainBox {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
  pointer-events: none;
}

.between /deep/ .el-range-input {
  background-color: transparent;
}

.between /deep/ .el-date-editor .el-range-input {
  color: #fff;
  font-size: 12px;
}

.between /deep/ .el-range-editor.el-input__inner {

  background-color: rgba(14, 51, 59, 0.5);
  width: 50%;
  height: 32px;
  border: #2b8e85 1px solid;
}

/deep/ .el-date-editor .el-range__icon {
  line-height: 20px;
}

.translations {
  transform: translateX(-100%);
}

.translations2 {
  transform: translateX(100%);
}

#lefts {
  width: 27%;
  height: 99%;
  position: absolute;
  transition: transform 1s;
  left: 0px;
  bottom: 0;
  z-index: 47;

  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0 50px;
}

.center {
  width: 70%;
  height: 95vh;
  margin-top: 11vh;
  /* display: flex;
    flex-direction: column; */
  position: absolute;
  left: 50%;
  bottom: -3vh;
  margin-left: -34.5%;

  .between {
    position: absolute;
    bottom: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 17%;
    pointer-events: all;

    .villageInfo {
      position: relative;
      height: 280px;
    }
  }
}

#center {
  width: 70%;
  height: 60px;
  margin-top: 11vh;
  line-height: 60px;
  font-size: 24px;
  text-align: center;
  /* display: flex;
    flex-direction: column; */
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -34.5%;

  .between {
    position: absolute;
    bottom: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 17%;
    pointer-events: all;

    .villageInfo {
      position: relative;
      height: 280px;
    }
  }
}
#rights {
  width: 27%;
  height: 99%;
  position: absolute;
  transition: transform 1s;
  bottom: 0;
  right: 0;
  z-index: 47;

  background-size: 97% 96%;
  background-repeat: no-repeat;
  background-position: 0 50px;
}

.bottoms {
  width: 45%;
  height: 90px;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translate(-50%, -50%);

  filter: blur(10px);
}

.leftBoxs {
  width: 92%;
  height: 94vh;
  margin-top: 90px;
  margin-left: 6%;
  pointer-events: all;
  .survey{
    .jidi-text{
      font-size:14px;
      color:rgba(255,255,255,.75);
      line-height:22px;
      letter-spacing: 1px;
      padding-bottom: 20px;
      text-indent: 2em;
    }
  }
}

.rightBox {
  width: 92%;
  height: 94vh;
  margin-top: 90px;
  margin-right: 6%;
  pointer-events: all;

  .villageInfo {
    height: 26vh;
  }

  .survey {
    height: 34vh;
    position: relative;

    .equitment {
      img {
        width: 0.3rem;
        height: 0.3rem;
      }
    }

  }

  .partyBox {
    height: 240px;
  }
}

.villageInfo {
  width: 100%;
  //height: 33vh;
  overflow: hidden;

  .carousel{
    width: 100%;
    height: 230px;
    margin: 5px auto 0;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .el-carousel{
    height: 230px;
  }
  .el-carousel /deep/.el-carousel__container{
    height: 230px;
  }
  .el-carousel__item{
    background-color: transparent;
  }

}

.carousel-detail {
  width: 40%;
  height: 200px;
  margin-top: 5px;
  border-radius: 8px;

  img {
    width: 100%;
    height: 90%;
    border-radius: 8px;
  }
}
.carouselBig{
  width: 100%;
  height: 100%;
  //margin: 10px auto 0;
  display: flex;
  justify-content: space-evenly;
  img{
    max-width: 100%;
    max-height: 100%;
  }
  .box{
    margin: auto;
  }
  .el-carousel{
    height: 100%;
  }
  .el-carousel /deep/.el-carousel__container{
    height: 100%;
  }
  .el-carousel__item{
    background-color: transparent;
  }
}

.arrow {
  margin-top: 10px;
  padding: 10px;
  background-image: url(~@/assets/index/chkded.png);
  background-size: 100% 92%;
  background-repeat: no-repeat;

  .jidi-text {
    width: 58% !important;
    display: flex;
    flex-direction: column;

    .update {
      color: #66ffff;
      font-size: 16px;
    }

    .equit {
      margin: 8px 0 2px 8px;

      .arrow-bg {
        position: relative;
        top: 3px;
        display: inline-block;
        width: 16px;
        height: 16px;
        background-image: url(~@/assets/index/arrow.png);
        background-size: 100% 100%;
      }

      .arrow-key {
        margin-left: 8px;
        font-size: 15px;
        color: #c3fffc;
      }

      .arrow-val {
        font-size: 14px;
        color: #fff;
        margin-left: 5px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 55%;
        height: 16px;
      }
    }
  }
}

.carousel {
  width: 100%;
  // height: 26vh;
  margin-top: 5px;
  border-radius: 8px;

  img {
    //max-height: 100%;
    width:100%;
    border-radius: 8px;

  }

  /deep/ .el-carousel__button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #979797;
  }
}

.el-carousel {
  // height: 26vh;
}



.el-carousel__item {
  background-color: transparent;
}

.infos{
  width: 100%;
  height: 180px;
  margin-top: 5px;
  background: url("~@/assets/cloud2/index/info.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.infoTitle{
  width: 35%;
  height: 35px;
  text-align: center;
  margin: 10px auto 0;
  line-height: 35px;
  font-size: 18px;
  font-family: 'mokuaiTitile';
}

.villageFont {
  width: 95%;
  height: 110px;
  margin: 15px auto 0;
  font-size: 14px;
  font-family: "mokuaiXi";
  letter-spacing: 1px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.boxTitile {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  background: url("~@/assets/cloud2/index/boxtitle.png") no-repeat;
  background-size: 100% 100%;
  font-size: 22px;
  font-family: "mokuaiTitile";
  font-weight: bold;
  line-height: 45px;
  font-style: normal;

  span {
    width: 85%;
    display: inline-block;
    margin-left: 6%;
    background: linear-gradient(to bottom, #fff 40%, #97fcff 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .choose {
    color: #c3fffc;
    font-size: 16px;
  }

  .el-input__inner {
    width: 160px;
  }

  .el-input__suffix {
    right: 20px;
  }
}

.base-select {
  width: 60%;
  margin-right: 10px;

  /deep/ .el-input__inner {
    background-color: rgba(11, 52, 117,.5);
    border: 1px solid #113f44;
    color: #fff;
    font-size: 14px;
  }
}

.common-box {
  width: 100%;
  // height: 90px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;

  .land-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: auto;
    height: 260px;


    .popUpItem{
      width: 32%;
      height: 65px;
      background-color: rgb(37, 64, 107);
      margin-bottom: 5px;
    }
    .popUpItem:nth-child(4n){
      margin-right: 0;
    }
    .popUpItem img{
      width: 40%;
      height: 55px;
      float: left;
      margin-left: 5px;
      margin-top: 5px;
    }

    .itemInfo{
      width: 51%;
      float: left;
      margin-left: 5px;
      margin-top: 5px;
      height: 55px;
      font-size: 14px;
      font-family: 'mokuaiXi';
      p{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .itemInfo p:nth-child(1){
      margin-top: 7px;
    }
    .itemInfo p:nth-child(2){
      font-family: 'number2';
      font-size: 14px;
      background: linear-gradient(to bottom, #fff 50%, #dbb542 50%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }




    .equitment {
      width: 205px;
      height: 65px;
      display: flex;
      margin-bottom: 5px;

      .land-value {
        display: flex;
        flex-direction: column;
        width: 70%;

        .text-gray {
          padding-left: 10px;
          text-align: left;
          line-height: 34px;
          background: url(~@/assets/index/poline.png) repeat-x 100% 100%;
        }

        .text-white {
          padding-top: 5px;
          padding-left: 10px;
          text-align: left;
        }

        .number {
          font-size: 16px;
        }
      }

      .land-icon {
        width: 0.3rem;
        height: 0.3rem;
        margin-right: 0.03rem;
      }

      .text-gray {
        font-size: 16px;
        color: #b1e1ff;
      }

      .number {
        font-size: 26px;
        color: #fefefe;
      }

      .unit {
        font-size: 16px;
        color: #aac2d2;
      }
    }
  }
}

.surveyBox {
  &:extend(.common-box);
  background: url("~@/assets/cloud2/index/equipment.png") no-repeat;
  background-size: contain;
  margin-bottom: 10px;
}

.guan {
  flex-wrap: wrap;
  padding: 10px 8px;
  height: 215px;
  overflow: auto;

  .guan-list {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    width: 48%;
    margin-bottom: 5px;
    box-sizing: border-box;
    height: 100px;
    background: url("~@/assets/index/guan.png") no-repeat;
    background-size: cover;

    .content {
      width: 100%;
      padding: 0 10px;

      .switch {
        padding-top: 15px;
        display: flex;
        justify-content: space-between;

        span {
          display: inline-block;
          width: 48%;
          text-align: center;
          cursor: pointer;
          height: 30px;
          line-height: 30px;
          font-size: 14px;

        }

        .clsBtn {
          background: url("~@/assets/index/close.png") no-repeat;
          background-size: contain;
          background-position: right;

        }

        .openBtn {
          background: url("~@/assets/index/open.png") no-repeat;
          background-size: contain;
          background-position: left;
        }

      }
    }

    .guaname {
      font-size: 16px;
      color: #fff;
    }

    .number {
      font-size: 18px;
    }

    .accont {
      font-size: 16px;
    }
  }
}

.holdsAll {
  width: 33%;
  height: 70px;
  text-align: center;
  margin-left: 0.5rem;
}

.moneyAll {
  width: 33%;
  height: 70px;
  text-align: center;
}

.peopleAll {
  width: 33%;
  height: 70px;
  text-align: center;
}

.areaAll {
  width: 33%;
  height: 70px;
  text-align: center;
}

.land {
  padding: 10px 10px 15px;

  /deep/ .el-input__inner {
    height: 32px;
  }

  .land-list {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: auto;
    height: 180px;

    .equitment {
      width: 205px;
      height: 40px;
      line-height: 40px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;

      div {
        flex: 1;
        text-align: center;
      }

      .text-gray {
        font-size: 15px;
        color: #b1e1ff;
        background: url(~@/assets/index/equitment.png) no-repeat 100% 100%;
      }

      .text-white {
        background: url(~@/assets/index/equitment-right.png) no-repeat 100% 100%;
      }

      .number {
        font-size: 18px;
        color: #fefefe;
      }

      .unit {
        font-size: 16px;
        color: #aac2d2;
      }
    }

    .equitment:last-child {
      // width: 300px;
      .number {
        font-size: 16px;
      }
    }
  }

  .choose {
    color: #c3fffc;
    font-size: 16px;
  }

  .el-input__inner {
    width: 160px;
  }

  .el-input__suffix {
    right: 20px;
  }
}

.surveyBox p {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "mokuaiTitile";
}

.surveyBox p:nth-child(odd) {
  margin-top: 15px;
}

.surveyBox span {
  font-size: 28px;
  /*  background: linear-gradient(to bottom, #fff 40%, #97fcff 60%); */
  color: #fff;
  -webkit-background-clip: text;
  /*   -webkit-text-fill-color: transparent; */
  font-family: "number";
  margin-right: 5px;
}

.surveyBox .total-color {
  color: #4695f3;
}

.left2 {
  width: 100%;
  position: relative;
  .distance-first5 {
    width: 100%;
    height: 50vh;
    overflow: hidden;
    position: relative;
    //top: 25px;
    z-index: 1000;

    .distance-swiper {
      width: 100%;
      height: 100%;
      // overflow: hidden;

      .distance-content {
        width: 100%;
        display: flex;
        justify-content: space-between;



        .distance-content-right {
          background: url("~@/assets/index/list_bg.png") no-repeat;
          background-size: 100% 100%;
          width: 100%;

          .distance-content-right-title {
            display: flex;
            justify-content: space-between;
            font-size: 15px;
            color: #fff;
            padding-left: 15px;
            padding-top: 5px;
            padding-right: 15px;
            font-weight: 600;

            .time {
              color: #cabbbb;
              font-weight: 400;
            }
          }

          .distance-content-right-text {
            display: flex;
            //height: calc(158px - 55px);
            font-size: 15px;
            color: #fff;
            padding: 15px;
            align-items: center;

            img {
              width: 100px;
            }

            .distance-content-right-text-info {
              font-size: 12px;
              color: #c1c5b9;
              padding-left: 20px;

              div {
                padding-top: 5px;
              }
            }
          }
        }
      }
    }
    .nodata {
      text-align: center;
      padding-top: 20%;
      font-size: 15px;
      color: #02e9ff;
    }
  }
}
.left3{
  height:30vh;
  width:100%;
  .carousel{
    width: 90%;
    height: 25vh;
    margin: 10px auto 0;
    img{
      //width: 100%;
      height: 100%;
    }
  }
  .el-carousel{
    height: 25vh;
  }
  .el-carousel /deep/.el-carousel__container{
    height: 25vh;
  }
  .el-carousel__item{
    background-color: transparent;
  }
}


.partyBox{
  width: 100%;
  height: 225px;
  margin-top: 15px;
}
.leftCard{
  width: 30%;
  height: 225px;
  float: left;
}
.memberTop{
  font-size: 12px;
  margin-left: 58px;
  line-height: 40px;
}
.memberNum{
  font-size: 24px;
  font-weight: bold;
  margin-left: 68px;
  line-height: 5px;
  font-family: 'number';
}
.memberAll{
  width: 100%;
  height: 65px;
  background: url("~@/assets/cloud2/index/card1.png") no-repeat;
}
.fullMember{
  width: 100%;
  height: 65px;
  background: url("~@/assets/cloud2/index/card2.png") no-repeat;
  margin-top: 10px;
}
.memberTwo{
  width: 100%;
  height: 65px;
  background: url("~@/assets/cloud2/index/card3.png") no-repeat;
  margin-top: 10px;
}
.distribution{
  width: 65%;
  height: 225px;
  float: left;
}

.bution {
  width: 100%;
  height: 28px;
  background: url("~@/assets/cloud2/index/fenbu.png") no-repeat;
  background-size: 100% 100%;

  div {
    font-size: 18px;
    margin-left: 20px;
    /*  line-height: 30px; */
    float: left;
    font-weight: bold;
    background: linear-gradient(to bottom, #fff 40%, #97fcff 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-style: italic;
  }
}
#echarts{
  width: 100%;
  margin-top: 15px;
}
#echarts1 {
  width: 100%;
  height: 200px;
  margin-top: 15px;
}
#echarts6 {
  width: 100%;
  height: 175px;
  margin-top: 15px;
}
.links {
  width: 20%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  background: url("~@/assets/index/detail-btn.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 15px;
  font-family: "mokuaiXi";
  cursor: pointer;
}

.bigData {
  width: 100%;
  height: 235px;
  position: relative;
}

.dataBox {
  width: 100%;
  height: 190px;
  margin-top: 10px;
}

#echarts2 {
  width: 100%;
  height: 100%;
}

.governance {
  width: 100%;
  height: 235px;
  position: relative;
}

.goverBox {
  width: 100%;
  height: 190px;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.goverBox p {
  font-size: 14px;
  // font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 20px;
}

.goverBox p:nth-child(even) {
  margin-top: -2px;
}

.goverBox span {
  font-size: 30px;
  color: #80f6fa;
  margin-right: 5px;
  font-family: "number";
}

.goverItem {
  width: 40%;
  height: 65px;
  background: url("~@/assets/index/allhu.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
}

.goverItem2 {
  width: 40%;
  height: 65px;
  background: url("~@/assets/index/allren.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
}

.goverItem3 {
  width: 40%;
  height: 65px;
  background: url("~@/assets/index/changren.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
}

.goverItem4 {
  width: 40%;
  height: 65px;
  background: url("~@/assets/index/liuren.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
}

.thirdAsset {
  width: 100%;
  height: 235px;
  position: relative;
}

.thirdBox {
  width: 100%;
  height: 190px;
  margin-top: 10px;
}

#echarts3 {
  width: 100%;
  height: 100%;
}

.authority {
  width: 100%;
  height: 235px;
  position: relative;
}

.authorityBox {
  width: 100%;
  height: 190px;
  margin-top: 10px;
}

#echarts4 {
  width: 100%;
  height: 100%;
}

.controlButton {
  width: 20%;
  height: 30px;
  position: fixed;
  bottom: 2%;
  left: 40%;
  display: flex;
  justify-content: space-between;
}

.rotating {
  width: 25%;
  height: 30px;
  // border: #2ab3b8 1px solid;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  background-color: #1e4c54;
  font-size: 15px;
  cursor: pointer;
  box-shadow: 0px 0px 3px 3px #2ab3b8;
  box-sizing: border-box;
}

// .rotating:hover{
//   background-color: #1E4C54;
// }
.dimensions {
  width: 25%;
  height: 30px;
  border-radius: 10px;
  text-align: center;
  line-height: 30px;
  background-color: #1e4c54;
  font-size: 15px;
  cursor: pointer;
  box-shadow: 0px 0px 3px 3px #2ab3b8;
}

// .dimensions:hover{
//   background-color: #1E4C54;
// }
.threeD {
  color: #fff;
  background-color: #979797;
}

.imgBigBox {
  width: 95%;
  height: 85%;
  border: #25406b 1px solid;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(37, 64, 107);
  border-radius: 10px;
  z-index: 999;
}

.popUpTop {
  width: 100%;
  height: 50px;
  font-family: "mokuaiTitile";
  font-size: 20px;
  font-style: italic;
  line-height: 50px;
  background: url("~@/assets/cloud2/villageRural/tanTop.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  span {
    margin-left: 65px;
  }

  .closeCss {
    margin-left: 0;
    float: right;
    top: 5px;
    right: 20px;
    position: absolute;
    font-size: 30px;
    cursor: pointer;
  }

  img {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}

.imgInfo {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #25406b;
  border: 1px solid #25406b;
}



/deep/ .el-select-dropdown {
  background-color: #0e333b !important;
}

/deep/ .el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #0e333b;
}

.el-select-dropdown__item {
  padding-left: 10px;
}

.el-select-dropdown__item.hover {
  background-color: #0e2325;
}

.middle-text {
  position: absolute;
  width: 90px;
  height: 90px;
  background: #124C5A;
  border-radius: 50%;
  top: 43.5%;
  left: 43%;
  text-align: center;
  font-size: 14px;
  padding-top: 30px;
}

/deep/ .dv-scroll-board {
  margin-top: 10px;
}

/deep/ .dv-scroll-board .header {
  height: auto;
  background: url(~@/assets/index/alarm.png) repeat-x;
  background-color: transparent !important;
  background-size: cover;
  margin: auto;
  width: 100%;
}

/deep/ .dv-scroll-board .rows .row-item {
  background: url(~@/assets/index/alarm-box.png) repeat-x;
  background-color: transparent !important;
  background-size: cover;
}

.link-btn {
  position: absolute;
  top: 50px;
  width: 100%;
  z-index: 10;
  box-sizing: border-box;
  padding:0 15px;
}

.link-btn-item {
  width: 8%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  float:left;
  font-family: "mokuaiTitile";
  font-weight: 200;
  border-right:1px solid #25406b;
}
.link-btn-item:hover{
  color:#ffcd35;
  //font-weight: bold;
}
.link-btn-item:nth-child(3){
  color:#ffcd35;
  background-image: url("~@/assets/cloud2/index/top_select.png");
  background-position: center bottom;
  background-size: 100%;
  background-repeat: no-repeat;

}
.link-btn-item:nth-child(4){
  margin-left: 50%;

}

.link-btn-item:last-child{
  float:right;
}

.legends{
  position: absolute;
  bottom: 3%;
  left: 27%;
  width: 14%;
  height: 210px;
  background: url("~@/assets/cloud2/index/legend.png") no-repeat;
  background-size: 100% 100%;
}
.legendTop{
  width: 80%;
  height: 45px;
  margin: 0 auto 0;
  border-bottom: 1px dashed #49929E;
  text-align: center;
  line-height: 45px;
  font-style: italic;
  font-size: 15px;
  background: linear-gradient(to bottom, #fff 50%, #97fcff 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.legendInfo{
  width: 80%;
  height: 150px;
  margin: 5px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.legendItem{
  width: 48%;
  height: 30px;
  font-size: 14px;
  position: relative;
  cursor: pointer;
  img{
    width: 30%;
    height: 30px;
  }
  span{
    position: absolute;
    top: 5px;
    left: 33px;
  }
}
.actives{
  color: #999;
}
.mapChangeBox{
  position: absolute;
  top: 22%;
  left: 29%;
  width: 33%;
  height: 270px;
  background-color: rgb(0, 99, 255);
  border-radius: 10px;
}

/deep/ .dv-scroll-board{
  width:100%;
  height: calc(100% - 70px);
}
/deep/ .dv-scroll-board .header {
  width: 100%;
  margin: 0 auto;
  background: url('~@/assets/cloud2/chanye/alarm.png') repeat-x;
  background-color: transparent !important;
  background-size: cover;
  height: 35px;
  color: #0072fa;
  margin-bottom: 5px;
  font-size: 14px;
  font-size: 14px;
}
/deep/ .dv-scroll-board .rows{
  width: 100%;
  margin: 0 auto;
  background-color: rgba(12, 52, 105, 0.3) !important;
  border-radius: 5px;

}
/deep/ .dv-scroll-board .rows .row-item{
  height: 33px;
  line-height: 33px;
  font-size: 14px;
  background-color: transparent !important;
  color:rgba(255,255,255,.75)

}
// .container-item-box2 /deep/ .dv-scroll-board{
//     width:100%;
//     height: calc(100% - 70px) !important;
// }


.icon{
  font-size: 40px;
}


</style>

